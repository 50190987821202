<template>
    <div v-if="me && profile" class="edit-profile">
        <StackRouterHeaderBar
            :class="{ 'bottom-border': showHeaderTitle }"
            :title="$translate('EDIT_PROFILE')"
            :show-title="true"
            :leftButtonHandler="back"
            :right-button="isEnvTest ? 'reorder' : 'none'"
            :right-button-handler="isEnvTest ? createDummyProfileMd : () => {}"
        />
        <main class="main" @scroll="onScroll" ref="main">
            <div v-if="me.profile.status === 'confirmed'" class="status10-edit-description">
                <ul class="status10-edit-items">
                    <li class="list-item">별표시(<span class="star">*</span>)는 필수 입력사항 입니다.</li>
                    <li class="list-item">프로필 변경은 직접 가능합니다. 변경된 내용은 매니저 확인 후 반영됩니다.</li>
                </ul>
            </div>
            <div v-else class="required-description">
                *해당 표시는 <span class="blue-highlight-text">필수입력사항</span> 입니다.
            </div>
            <EditPhotos v-model="me.photos" @onChangeDeletePhotoIds="onChangeDeletePhotoIds" />

            <div>
                <EditProfileItemsV2 :sections="sections" />
            </div>

            <EditKeywords />
        </main>
        <BottomButton @click="back" :blackBtn="true" :label="$translate('SAVE')" />
    </div>
</template>

<script>
import EditPhotos from './components/EditPhotos'
// import EditProfileItems from './components/EditProfileItems'
import profileService from '@/services/profile'
// import EditProfileTabs from './components/EditProfileTabs'
import EditKeywords from './components/EditKeywords'
import EditProfileItemsV2 from '@/routes/my-page/EditProfileItemsV2'
import { createDummyProfile } from '@/assets/constants/dummy-profile'

export default {
    name: 'EditProfilePage',
    components: {
        EditPhotos,
        // EditProfileItems,
        // EditProfileTabs,
        EditKeywords,
        EditProfileItemsV2,
    },
    data: () => ({
        deletePhotoIds: [],
        selectedTab: '',
        showHeaderTitle: false,
    }),
    // props: {
    //     initialTab: String,
    // },
    watch: {
        selectedTab(newTab, oldTab) {
            if (oldTab) {
                const mainDom = this.$refs.main
                mainDom.scrollTop = 0
            }
        },
        '$store.getters.pageStack': {
            handler(newVal) {
                if (newVal[newVal.length - 1].name === 'EditProfilePage') {
                    this.$registerBackHandler(this.back)
                }
            },
            deep: true,
        },
    },
    computed: {
        isEnvTest() {
            return process.env.NODE_ENV === 'test'
        },
        submitAllRequired() {
            return this.$store.getters.submitAllRequired
        },
        me() {
            return this.$store.getters.me
        },
        profile() {
            return this.$store.getters.profile
        },
        profileUpdateRequests() {
            return this.$store.getters.profileUpdateRequests
        },
        sections() {
            if (!this.profile) return

            return this.$sections()
        },
        needPhotoUpdate() {
            return this.me.photos.some(item => item.blob)
        },
    },
    methods: {
        async createDummyProfileMd() {
            const idx = await this.$modal.basic({
                body: '더미 프로필을 생성합니다. 스테이징 환경이 확실합니꽈??',
                buttons: [
                    {
                        label: 'CANCEL',
                        class: 'btn-default',
                    },
                    {
                        label: 'CONFIRM',
                        class: 'btn-primary',
                    },
                ],
            })
            if (idx) {
                const dummyProfile = createDummyProfile(this.profile)

                this.$store.commit('setProfile', dummyProfile)
            }
        },
        init() {
            // this.selectedTab = this.initialTab || 'PHOTO'
            this.$store
                .dispatch('loadProfile')
                .then(() => this.$store.dispatch('loadPur'))
                .catch(e => {
                    console.log(e)
                    this.$toast.error(e.data)
                })
        },
        async saveProfile() {
            try {
                this.$loading(true)
                let interval
                if (this.needPhotoUpdate) {
                    this.$toast.success('사진을 저장중입니다')
                    interval = setInterval(() => {
                        this.$toast.success('사진을 저장중입니다')
                    }, 2000)
                }
                const { first_complete_profile: complete } = await profileService.update(this.preparedPayload())
                if (complete) {
                    this.$modal.basic({
                        title: '프로필 입력이 완료되었습니다!',
                        body:
                            '작성이 잘 되었는지 매니저가 확인할 예정이에요. 기다리는 동안 매칭 선호 사항과 필요 서류를 제출해주세요!',
                        buttons: [
                            {
                                label: '확인',
                                class: 'btn-primary',
                            },
                        ],
                    })

                    if (this.$route.name === 'ChatroomPage') {
                        this.$store.dispatch('loadChat', { chatId: this.$store.getters.chat.id, force: true })
                    }
                }

                if (interval) clearInterval(interval)

                this.$toast.success('매니저 승인 후 변경사항이 반영됩니다')
                await this.$store.dispatch('loadMe')
                this.$stackRouter.pop()
                this.$store.dispatch('loadSettings')
            } catch (e) {
                this.$toast.error(e.data)
                console.log(e)
                this.$stackRouter.pop()
            } finally {
                this.$loading(false)
            }
        },
        userInputAllowed(key) {
            return ['job', 'jobType', 'company', 'university', 'major'].indexOf(key) !== -1
        },
        onChangeDeletePhotoIds(deletePhotoIds) {
            this.deletePhotoIds = deletePhotoIds
        },
        preparedPayload() {
            const keys = this.sections.reduce((result, keys) => {
                return result.concat(keys.column)
            }, [])
            const form = new FormData()
            form.append('id', this.profile.id)
            keys.forEach(key => {
                const snake = this.$case.toSnake(key)
                if (this.profile[snake] === null) return

                if (snake === 'smoking') {
                    form.append('smoking', this.profile.smoking ? '1' : '0')
                    return
                }

                if (this.$profile.needProfileOption(snake)) return

                if (snake === 'car') {
                    if (this.profile.car) {
                        console.log(1)
                        // form.append('car_brand', null)
                        form.append('car_model', this.profile.car_model)
                        return
                    } else {
                        form.append('car_model', '')
                    }
                }

                if (snake === 'jobDescription' && this.profile.job_description) {
                    form.append('job_description', this.profile.job_description)
                    return
                }

                if (snake === 'state' || snake === 'hometown') {
                    try {
                        const prefix = snake !== 'state' ? `${snake}_` : ''
                        form.append(`${prefix}state_id`, this.profile[`${prefix}state`].id)
                        if (this.profile[`${prefix}state`].is_station) {
                            form.append(`${prefix}station_id`, this.profile[`${prefix}station`].id)
                        } else {
                            form.append(`${prefix}region_id`, this.profile[`${prefix}region`].id)
                        }
                    } catch (e) {
                        return
                    }

                    return
                }

                if (snake === 'bachelor' || snake === 'master' || snake === 'doctor') {
                    form.append(`${snake}_u_id`, this.profile[`${snake}_u_id`])
                    form.append(`${snake}_u_name`, this.profile[`${snake}_u_name`] || '')
                    form.append(`${snake}_c_id`, this.profile[`${snake}_c_id`])
                    form.append(`${snake}_c_name`, this.profile[`${snake}_c_name`] || '')
                    form.append(`${snake}_m_id`, this.profile[`${snake}_m_id`])
                    form.append(`${snake}_m_name`, this.profile[`${snake}_m_name`] || '')

                    form.append(`${snake}_start`, this.profile[`${snake}_start`])
                    form.append(`${snake}_end`, this.profile[`${snake}_end`])
                    form.append(`${snake}_status`, this.profile[`${snake}_status`])
                    return
                }

                if (snake === 'high') {
                    form.append('high_school_name', this.profile.high_school_name || '')
                    form.append('high_school_start', this.profile.high_school_start)
                    form.append('high_school_end', this.profile.high_school_end)
                    form.append('high_school_status', this.profile.high_school_status)
                    return
                }

                if (snake === 'family_relations') {
                    form.append('brother_count', this.profile.brother_count)
                    form.append('sister_count', this.profile.sister_count)
                    form.append('order_of_children', this.profile.order_of_children)
                    return
                }

                if (snake === 'health_status') {
                    form.append('health_status', this.profile.health_status)
                    form.append('health_detail', this.profile.health_detail || '')
                    return
                }

                if (snake === 'asset') {
                    form.append('asset', this.profile[snake])
                    if (this.profile.asset_detail) form.append('asset_detail', this.profile.asset_detail)
                    return
                }

                const validProfileKey = this.profile[snake] !== undefined
                if (validProfileKey) form.append(`${snake}`, this.profile[snake])
            })

            form.append('house_owner', this.profile.house_owner !== null ? this.profile.house_owner : '')

            const profileOptionIds = this.profile.profile_options.map(po => po.profile_option_id)

            form.append('company', JSON.stringify(this.profile.company))
            form.append('family', JSON.stringify(this.profile.family))
            form.append('privacy_setting', JSON.stringify(this.profile.privacy_setting))
            form.append('profile_option_ids', JSON.stringify(profileOptionIds))

            // this.me.photos.filter(p => p.blob).forEach((p, idx) => form.append(`photo${idx + 1}`, p.blob, p.fileName))
            this.me.photos
                .filter(p => p.blob)
                .forEach((p, idx) => {
                    form.append(`photo${idx + 1}`, p.blob, p.fileName)
                })
            form.append('delete_photo_ids', JSON.stringify(this.deletePhotoIds))

            return form
        },
        onScroll(event) {
            const scrollTop = event.target.scrollTop

            scrollTop >= 48 ? (this.showHeaderTitle = true) : (this.showHeaderTitle = false)
        },
        async back() {
            // 나갈 때 최신 프로필로 상태 업데이트

            if (this.$store.getters.submitAllRequired === false) {
                const idx = await this.$modal.custom({
                    component: 'ModalVerticalButtons',
                    options: {
                        title: '아래 사항의 입력이 누락되었습니다. 다시 한번 확인해주세요!',
                        listItem: this.$store.getters.emptyRequiredField,
                        buttons: [
                            {
                                label: '마저 입력하기',
                                style: {
                                    bgColor: '#111111',
                                    color: '#FFFFFF',
                                    border: 'none',
                                },
                            },
                            {
                                label: '임시 저장하고 나가기',
                                style: {
                                    bgColor: 'none',
                                    color: '#111111',
                                    border: '1px solid #B9BDC1',
                                },
                            },
                        ],
                        height: '48px',
                    },
                })
                if (idx) {
                    await this.saveProfile()
                }
            } else {
                await this.saveProfile()
            }
        },
    },
    created() {
        this.init()
        this.$registerBackHandler(this.back)
    },
    beforeDestroy() {
        this.$unregisterBackHandler(this.$router.currentRoute.name)
    },
}
</script>

<style lang="scss" scoped>
$edit-profile-padding: 16px;
$header-height: 52px;

::v-deep .bottom-button {
    position: absolute;
}
.edit-profile {
    .header-bar {
        padding: 8px 16px;
        height: $header-height;
        display: flex;
        align-items: center;
        justify-content: center;

        & > * {
            margin-right: auto;
        }
        .material-icons {
            color: $grey-08;
        }

        .header-title {
            font-size: 16px;
            color: black;
            font-weight: 500;

            @include f-medium;
        }
    }

    .main {
        overflow-y: scroll;
        height: calc(100vh - #{$header-height} - 81px);

        .status10-edit-description {
            background-color: #f7f8f9;
            border-radius: 12px;
            margin: 24px 16px 0;
            padding: 8px 12px;
            font-size: 14px;
            line-height: 24px;
            .status10-edit-items {
                display: list-item;
                margin-left: 16px;
                color: #111111;
                .list-item {
                    display: list-item;
                    list-style-type: disc;
                    .star {
                        color: $blue-primary;
                    }
                }
            }
        }
    }

    .edit-profile-tabs {
        position: sticky;
        top: 0;
        background-color: white;
    }

    .page-header {
        margin-bottom: 16px;

        .title {
            line-height: normal;

            @include f-medium;
        }
    }

    .hr {
        margin: 32px 0;
        height: 1px;
        background: $grey-02;
    }

    .edit-intro {
        margin-top: 32px;
    }
}
</style>
